import Parse from "parse";
import config from "../config";
import moment from 'moment'
import axios from "axios";

const PARSE_SERVER_URL = config.parseServerUrl
const PARSE_APP_ID = config.appId
const PARSE_JAVASCRIPT_KEY = config.javascriptKey
const PARSE_MASTER_KEY = config.masterKey
const API_URL = config.apiUrl;

Array.prototype.sumByKey = function (prop) {
    let sum = this.map(o => prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, o)).reduce((a, c) => { return a + c }, 0);
    return sum
}

export const init = async () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.masterKey = PARSE_MASTER_KEY
    Parse.serverURL = PARSE_SERVER_URL
};
export const customerLogout = async () => {
    init()
    await Parse.User.logOut()
}
export const login = async userInfo => {
    init()
    if (!userInfo.username || !userInfo.password) {
        return {
            error: 'please fill in the input'
        };
    }
    const user = await Parse
        .User
        .logIn(userInfo.username.toLowerCase(), userInfo.password);
    if (user) {
        const isAdmin = user.get('isAdmin')
        if (!isAdmin) {
            return {
                error: 'ไม่ใช่บัญชีประเภท Admin'
            }
        }
        const reUser = await reToken(user)
        return reUser
    }
    return {
        error: 'Log in failed'
    }
}
export const register = async (userInfo) => {
    init()
    const user = new Parse.User();
    user.set("username", userInfo.phone);
    user.set("gameUsername", userInfo.username);
    user.set("password", userInfo.password);
    user.set("seethroughPassword", userInfo.password);
    user.set("name", userInfo.name);
    user.set("phone", userInfo.phone);
    user.set("lineId", userInfo.lineId);
    user.set("bankCode", userInfo.bankCode);
    user.set("bankAccountNumber", userInfo.bankAccountNumber);
    user.set("balance", 0);
    //user.set('expireDate',m15)
    // var acl = new Parse.ACL();
    // acl.setPublicReadAccess(true);
    // acl.setPublicWriteAccess(true);
    // user.setACL(acl);
    try {
        await user.signUp();
        //await Parse.User.logOut();
        return { success: true, user: user }
    } catch (error) {
        return { error: error.message }
    }
}

export const reToken = async user => {
    try {
        var expireTime = Math.round((new Date()).getTime() / 1000);
        expireTime += 36000
        user.set('token', user.sessionToken);
        user.set('expireTime', expireTime);
        user.set('lastLogin', new Date());
        await user.save(null, { useMasterKey: true })
        return user
    } catch (error) {
        return {
            error: error
        }
    }

}
export const checkIsExpireUser = async username => {
    init()
    const query = new Parse.Query(Parse.User);
    query.equalTo("username", username);
    const user = await query.first();
    if (user) {
        const expireDate = user.get('expireDate')
        const isAdmin = user.get('isAdmin')
        if (isAdmin) {
            await reToken(user)
            return false
        } if (!expireDate) {
            return { error: 'NEW_USER' }
        }
        else if (moment().isAfter(moment(expireDate))) {
            return { error: 'EXPIRED' }
        }
    }
    return false
}

export const checkDisabledUser = async username => {
    init()
    const query = new Parse.Query(Parse.User);
    query.equalTo("username", username);
    const user = await query.first();
    if (user) {
        const isDisable = user.get('disable')
        const isAdmin = user.get('isAdmin')
        if (isAdmin) {
            await reToken(user)
            return false
        }
        if (isDisable) {
            return { error: 'BANNED' }
        }
    }
    return false
}

export const customerLogin = async userInfo => {
    init()
    if (!userInfo.username || !userInfo.password) {
        return {
            error: 'ใส่ข้อมูลให้ครบ'
        };
    }
    const user = await Parse
        .User
        .logIn(userInfo.username.toLowerCase(), userInfo.password);
    if (user) {
        const reUser = await reToken(user)
        return reUser
    } else {
        return { error: 'username หรือ password ผิด' }
    }
}


export const closeConnection = async () => {
    init()
    Parse
        .LiveQuery
        .close();
    return
}
export const getAllObjects = async (className, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getObjectsCount = async (className, limit, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    if (limit) {
        query.limit(limit)
    }
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.count();

    //console.log(`results length:${results}`)
    return results
}

export const queryObjects = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const queryObjectsCount = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsByCreatedAt = async (className, start, end, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.greaterThanOrEqualTo('createdAt', start);
    query.lessThan('createdAt', end);

    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.birth = json.birth.iso
        json.expiryDate = json.expiryDate.iso
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}


export const trashObjectWithId = async (className, objectId, trashBool) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)
    if (result) {
        if (trashBool) {
            result.set('isTrash', trashBool)
        } else {
            result.unset('isTrash')
        }

        const res = await result.save().catch(error => {
            return { type: 'error', msg: error.message }
        })
        return { type: 'success', msg: 'Done!' }
    } else {
        return { type: 'error', msg: `Can\'t find ${className} with this ID` }
    }
}

export const getObjectWithId = async (className, objectId) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)

    //console.log(`getObjectWithId :${JSON.stringify(result)}`)
    return result
}
export const destroyObjectWithId = async (className, objectId) => {
    init()

    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)

    const res = await result.destroy().catch((error) => {
        //console.log(`destroyObjectWithId error:${JSON.stringify(error)}`)
        return { type: 'error', msg: 'Destroy Error! :' + error.message }
    });
    return { type: 'success', msg: 'Destroy Done!' }

}


export const createObject = async (className, req) => {
    init()
    //console.log(`createObject:${JSON.stringify(req)}`)

    const ClassName = Parse.Object.extend(className);
    const obj = new ClassName();
    try {
        const res = await obj.save(req)
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(true);
        res.setACL(acl);
        await res.save()
        return { type: 'success', msg: 'Done!', objectId: res.id, object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }


}

export const editObject = async (req) => {
    init()
    //console.log(`editObject:${JSON.stringify(req)}`)
    await req.save().catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const saveAllObject = async req => {
    init()
    //console.log(`saveAllObject req`, req)
    const res = await Parse.Object.saveAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}
export const fetchAllObject = async req => {
    init()
    //console.log(`saveAllObject req`, req)
    const res = await Parse.Object.fetchAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return res
}
export const destroyAllObject = async req => {
    init()
    const res = await Parse.Object.destroyAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const uploadParseFile = async file => {
    init()
    const uuid = new Date().getTime()
    //console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.${file.type === 'image/jpeg' ? 'jpg' : 'png'}`, file.originFileObj);
    const img = await parseFile.save().catch(error => {
        //console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }
    })
    //console.log(`upload img.url :  ${img.url()}`)
    const File = Parse.Object.extend("File")
    const obj = new File()
    obj.set('file', img)
    obj.save()
    return { url: img.url(), img: img }

}

export const uploadParseThumbnailFile = async file => {
    init()
    const uuid = new Date().getTime()
    //console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.jpg`, { base64: file });
    const img = await parseFile.save().catch(error => {
        //console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }
    })
    //console.log(`upload img.url :  ${img.url()}`)
    const File = Parse.Object.extend("Thumbnail")
    const obj = new File()
    obj.set('file', img)
    obj.save()
    return { url: img.url(), img: img }

}

export const queryObjectBySomeKey = async (className, key, value, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.equalTo(key, value);
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getValueById = async (className, key, objectId) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    return result.get(key)
}

export const resetPassword = async (objectId, password) => {
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(objectId);
    user.set('password', password)
    try {
        await user.save()
        return ({ type: 'success', msg: 'เรียบร้อย' })
    } catch (error) {
        return ({ type: 'error', msg: error.message })
    }
}

export const queryObjects2 = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('fromDate', dateFilter[0].startOf('day').toDate());
        query.lessThanOrEqualTo('toDate', dateFilter[1].endOf('day').toDate());
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getConfig = async () => {
    init()
    const config = await Parse.Config.get({useMasterKey:true})
    return config
}
export const saveConfig = async (data) => {
    init()
    try {
        const res = await Parse.Config.save(data, { useMasterKey: true })
        return { type: 'success', msg: "Save done!", config: res }

    } catch (error) {
        return { type: 'error', msg: error.message }

    }
    //console.log('saveConfig', data)
}

export const getCurrentUser = async () => {
    init()
    const user = await Parse.User.current();
    await user.fetch({ useMasterKey: true })
    return user
}
export const gameViewLogger = async (game) => {
    init()
    const user = await getCurrentUser()
    const GameView = Parse.Object.extend('GameView');
    const obj = new GameView()
    obj.set('user', user)
    obj.set('game', game)
    obj.set('date', new Date())
    try {
        const res = await obj.save()
        return { type: 'success', msg: 'Ok', object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}


export const adminLogger = async (type, activity, data) => {
    init()
    const user = await getCurrentUser()
    const Activity = Parse.Object.extend('Activity');
    const obj = new Activity()
    obj.set('user', user)
    obj.set('type', type)
    obj.set('activity', activity)
    if (data) {
        obj.set('data', data)
    }
    try {
        const res = await obj.save()
        return { type: 'success', msg: 'Ok', object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}

export const listenUserUpdate = async (objectId) => {
    init()
    let query = new Parse.Query('User');
    query.equalTo('objectId', objectId);
    let subscription = await query.subscribe();
    return subscription
}

export const getUserTransaction = async (userId) => {
    init()
    let wQuery = new Parse.Query('Withdraw');
    wQuery.equalTo('userId', userId);
    wQuery.limit(20);
    wQuery.descending('createdAt');
    const w = await wQuery.find();

    let dQuery = new Parse.Query('Deposit');
    dQuery.equalTo('userId', userId);
    dQuery.limit(20);
    dQuery.descending('createdAt');
    const d = await dQuery.find();
    const t = d.concat(w);

    const mapped = t.map(item => {
        const json = item.toJSON();
        json.className = item.className;
        return json
    }).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    return mapped.length >= 10 ? mapped.slice(0, 10) : mapped
}

export const getUserWithdraw = async (userId) => {
    init()
    let wQuery = new Parse.Query('Withdraw');
    wQuery.equalTo('userId', userId);
    wQuery.limit(20);
    wQuery.descending('createdAt');
    const w = await wQuery.find();

    const mapped = w.map(item => {
        const json = item.toJSON();
        json.className = item.className;
        return json
    }).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    return mapped.length >= 10 ? mapped.slice(0, 10) : mapped
}

export const getUserDeposit = async (userId) => {
    init()
    let dQuery = new Parse.Query('Deposit');
    dQuery.equalTo('userId', userId);
    dQuery.limit(20);
    dQuery.descending('createdAt');
    const d = await dQuery.find();

    const mapped = d.map(item => {
        const json = item.toJSON();
        json.className = item.className;
        return json
    }).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    return mapped.length >= 10 ? mapped.slice(0, 10) : mapped
}

export const getUserRebates = async (userId) => {
    init()
    let query = new Parse.Query('Rebate');
    query.equalTo('apiUser', userId);
    query.limit(10);
    query.descending('createdAt');
    const rebates = await query.find();
    const mapped = rebates.map(item => {
        const json = item.toJSON();
        json.className = item.className;
        json.parseObject = item
        return json
    })
    return mapped
}

export const getUserReferralBonus = async (userId) => {
    init()
    let query = new Parse.Query('ReferralBonus');
    query.equalTo('username', userId);
    query.limit(10);
    query.descending('createdAt');
    const rebates = await query.find();
    const mapped = rebates.map(item => {
        const json = item.toJSON();
        json.className = item.className;
        json.parseObject = item
        return json
    })
    return mapped
}

export const getUserWeeklyReferralBonus = async (username) => {
    init()
    let query = new Parse.Query('ReferralBonus');
    query.equalTo('username', username);
    query.limit(99999);
    query.equalTo('status','completed')
    query.greaterThanOrEqualTo('createdAt', moment().startOf('week').toDate())
    query.lessThan('createdAt', moment().endOf('week').toDate())
    query.descending('createdAt');
    query.select(['amount'])
    const rebates = await query.find();
    const sum = rebates.reduce((sum, item) => sum + item.get('amount'),0)
    return sum
}

export const getDownlines = async (userId) => {
    init()
    let query = new Parse.Query('User');
    query.equalTo('referral', userId);
    query.limit(9999);
    query.descending('createdAt');
    const donwlines = await query.find();
    const mapped = donwlines.map(item => {
        const json = item.toJSON();
        return json
    })
    return mapped
}

export const redeemRebate = async (objectId) => {
    init()
    const query = new Parse.Query('Rebate');
    const wd = await query.get(objectId);
    if (wd) {
        try {
            wd.set('status', 'completed');
            let logs = wd.get('logs') || {};
            logs.completed = Date.now();
            const amount = wd.get('rebate');
            const user = wd.get('user');
            await user.fetch();
            const oldBalance = user.get('balance')
            logs.oldBalance = oldBalance
            logs.amount = amount
            wd.set('logs', logs);
            await wd.save();
            user.increment('balance', amount);
            await user.save(null, { useMasterKey: true });
            return { type: 'success', msg: "รับสิทธิ์ เรียบร้อย!", data: { objectId, status: 'completed' } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}

export const getPaymentTransaction = async (objectId) => {
    const txData = await (await axios.get(`${API_URL}/deposit/paymentTransaction?objectId=${objectId}`)).data;
    if (txData.status === "success") {
        return txData.data;
    } else {
        return null;
    }
};